import React, { FC } from "react"
import Container from "react-bootstrap/Container"

import DangerouslySetInnerHtml from "gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml"
import SymptomBlock from "gatsby-theme-gaviscon/src/components/SymptomBlock"
import Button from "gatsby-theme-gaviscon/src/components/common/Button"
import ProductVariats from "gatsby-theme-gaviscon/src/components/ProductVariats"
import ProductFormats from "gatsby-theme-gaviscon/src/components/ProductFormats"
import GatsbyImage from "gatsby-theme-gaviscon/src/components/common/GatsbyImage"

import useScreenRecognition from "gatsby-theme-gaviscon/src/hooks/useScreenRecognition"
import "gatsby-theme-gaviscon/src/components/ProductDetails/ProductDetails.scss"
import "./ProductDetailsOverride.scss"
import IconCustom from "gatsby-theme-gaviscon/src/components/common/IconCustom"
import WhereToBuyCard from "gatsby-theme-gaviscon/src/components/WhereToBuy/WhereToBuyCard"

const ProductDetails: FC<ProductPageTypes.IProductDetails> = ({ productDetailsData }) => {
  const {
    descriptionShort,
    productImage,
    productImageAlt,
    symptoms,
    commonProductSettings: {
      availableFlavorsLabel,
      availableSizesLabel,
      buyNowLabel,
      chooseFormatLabel,
    },
    buyNow,
    buyNowCards,
    btnLogo,
    btnLogoAlt,
    buyNowButtonNotice,
    howItWorksButton,
    ariaHowItWorksLabel,
    flavours,
    sizes,
    otherFormats,
    currentFormat,
    ariaBuyNow,
    badgeImage,
    badgeAlt,
  } = productDetailsData;

  const { isNotMobile, isMobile } = useScreenRecognition()
  const buyNowPath = (buyNow?.[0]?.url || "").concat(
    buyNow?.[0]?.queryString || ""
  )
  const howItWorksPath = (howItWorksButton?.[0]?.url || "").concat(
    howItWorksButton?.[0]?.queryString || ""
  )

  return (
    <div className="product-details" data-testid="product-details">
      <Container fluid>
        <div className="product-details__grid">
          {productImage ? (
            <div className="product-details__image">
              {isNotMobile ? (
                <GatsbyImage
                  isLazyLoading={false}
                  style={{ overflow: "initial" }}
                  objectFit="contain"
                  objectPosition="50% 100%"
                  className="product-details__image-img"
                  fluid={productImage}
                  alt={productImageAlt}
                />
              ) : null}
              {isMobile ? (
                <GatsbyImage
                  isLazyLoading={false}
                  style={{ overflow: "initial" }}
                  objectFit="contain"
                  objectPosition="50% 100%"
                  className="product-details__image-img"
                  fluid={productImage}
                  alt={productImageAlt}
                  fadeIn={false}
                />
              ) : null}
            </div>
          ) : null}
          {buyNow?.length ? (
            <div className="product-details__buy">
              <Button
                to={buyNowPath}
                target={buyNow[0].target}
                ariaLabel={ariaBuyNow}
                variant="buy"
              >
                {buyNow[0].name}
                {btnLogo?.fallbackUrl ? (
                  <GatsbyImage
                    isLazyLoading={false}
                    objectFit="contain"
                    className="product-details__btn-logo"
                    fluid={btnLogo}
                    alt={btnLogoAlt}
                  />
                ) : (
                  <IconCustom icon="chevron-right" />
                )}
              </Button>
              {buyNowButtonNotice ? (
                <DangerouslySetInnerHtml
                  className="product-details__buy-notice"
                  html={buyNowButtonNotice}
                />
              ) : null}
            </div>
          ) : null}

          {howItWorksButton?.length ? (
            <div className="product-details__how-it-works">
              <Button
                to={howItWorksPath}
                target={howItWorksButton[0].target}
                ariaLabel={ariaHowItWorksLabel}
                variant="info"
              >
                {howItWorksButton[0].name}
              </Button>
            </div>
          ) : null}

          <div className="product-details__text">
            {descriptionShort ? (
              <DangerouslySetInnerHtml
                className="product-details__short-description"
                html={descriptionShort}
              />
            ) : null}
            {symptoms?.length
              ? symptoms.map(({ properties: { text, color } }) => (
                  <SymptomBlock key={text} text={text} color={color} />
                ))
              : null}
          </div>
        </div>
        {badgeImage?.fallbackUrl ? (
          <GatsbyImage
            objectFit="contain"
            className="product-details__badge"
            fluid={badgeImage}
            alt={badgeAlt}
          />
        ) : null}
        {currentFormat?.length ? (
          <ProductFormats
            {...{ otherFormats, currentFormat }}
            title={chooseFormatLabel}
          />
        ) : null}
        {flavours?.length ? (
          <ProductVariats title={availableFlavorsLabel} variants={flavours} />
        ) : null}
        {sizes?.length ? (
          <ProductVariats title={availableSizesLabel} variants={sizes} />
        ) : null}

        {buyNowCards?.length ? (
          <div className="product-details__buy-cards">
            <h2 className="product-details__buy-cards-title">{buyNowLabel}</h2>
            <div className="product-details__buy-list">
              {buyNowCards.map(
                ({ properties: { title, image, imageAlt, link } }) => (
                  <WhereToBuyCard
                    classes={`buy-now__button--${title}`}
                    key={title}
                    {...{ image, imageAlt, link }}
                  />
                )
              )}
            </div>
          </div>
        ) : null}
      </Container>
    </div>
  )
}

export default ProductDetails
