import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IQuoteBlockProps } from './model';
import './TextBlock.scss';

const TextBlock: FC<IQuoteBlockProps> = ({ text }) => (
  <DangerouslySetInnerHtml className="text-block" element="div" html={text} />
);

export default TextBlock;
